import React from 'react';
import vector from "../img/Vector.png";
const FlexiblePage = () => {
  return (
    <div id='explore'>
        <div className='bg-[url("components/img/bg.png")] px-[16%] py-5 h-auto max-lg:hidden '>
            <div className='bg-[url("components/img/circles.png")] bg-no-repeat bg-contain bg-center relative block justify-center align-middle py-5 max-md:py-8'>
                <div className='text-center mt-[5%] max-md:mt-[10%]'>
                    <h1 className='text-[30px] font-extrabold text-[#000000]'>What makes us  different from others.</h1>
                </div>
                <div className='w-full flex justify-evenly mt-[8%]'>
                    <div className=' w-[50%] grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Flexible payments</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>Rent with ease<br></br>and pay monthly</p>
                    </div>
                    <div className=' w-[50%] grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Verified Agent</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>Transact with verified agents<br></br>across Nigeria</p>
                    </div>
                    <div className=' w-[50%] grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Affordable legal fee</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>A 5% legal fee to ensure<br></br>reduced financial burdens</p>
                    </div>
                </div>
                <div className='w-full flex justify-between px-14 mt-[8%]'>
                   <div className=' w-[50%] grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Speedy Process</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>Explore, Inspect <br></br>and Rent properties in record time</p>
                    </div>
                    <div className=' w-[50%] grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Convenient </h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>No hassles, no stress, rent<br></br>and move in with Rentease</p>
                    </div>
                </div>
                <div className='flex justify-center mt-11'>
                <a rel="noreferrer" target="_blank" href="https://explorer.myrentease.co/"><button className='mt-5 bg-[#287BDC] border-2 border-transparent w-[152px] h-[50px] text-[#FFFFFF] text-[16px] rounded-md cursor-pointer'>Explore</button></a>
                </div>
            </div>
        </div>
        {/*mobile view*/}
        <div className='bg-[url("components/img/bg.png")] py-5 h-auto lg:hidden'>
            <div className='bg-[url("components/img/circles.png")] bg-no-repeat bg-contain bg-center relative block justify-center py-10 max-md:py-10'>
                <div className='text-center mt-[5%] max-md:mt-[20%] max-lg:mt-[20%]'>
                    <h1 className='text-[20px] font-extrabold text-[#000000]'>What makes us<br></br> different from others.</h1>
                </div>
                <div className='w-full flex justify-between mt-[10%]'>
                    <div className=' w-full grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Flexible payments</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>Rent with ease<br></br>and pay monthly</p>
                    </div>
                    <div className='w-full grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Verified Agent</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>Transact with verified<br></br>agents across Nigeria</p>
                    </div>
                </div>
                <div className='w-full grid justify-center mt-4'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Affordable legal fee</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>A 5% legal fee to ensure<br></br>reduced financial burdens</p>
                </div>
                <div className='w-full flex justify-between mt-[8%]'>
                    <div className=' w-full grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Speedy Process</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>Explore, Inspect and <br></br>rent properties in record time</p>
                    </div>
                    <div className='w-full grid justify-center'>
                        <div className='w-full flex justify-center'>
                            <img src={vector} alt='' className='w-[45px] h-auto'></img>
                        </div>
                        <h3 className='mt-2 text-[18px] font-bold text-[#000000] text-center'>Convenient</h3>
                        <p className=' mt-2 font-semibold text-[#6E6E74] text-[14px] text-center'>No hassles, no stress,<br></br> rent and move in with Rentease</p>
                    </div>
                </div>
                <div className='flex justify-center mt-11'>
                <a target="_blank" href="https://explorer.myrentease.co/" rel="noreferrer"><button className='mt-5 bg-[#287BDC] border-2 border-transparent w-[152px] h-[50px] text-[#FFFFFF] text-[16px] rounded-md cursor-pointer'>Explore</button></a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FlexiblePage
